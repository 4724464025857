import React, { useEffect, useState } from "react";
import { useAppContext } from "../../AppProvider";
import { LinkIcon } from "@heroicons/react/20/solid";

export default function Banner() {
  const { news } = useAppContext();
  const [currentNews, setCurrentNews] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    if (news.data && news.data.length > 0) {
      setCurrentNews(news.data[0]);
    }
  }, [news.data]);

  useEffect(() => {
    // Imposta un intervallo per cambiare la news corrente ogni 10 secondi
    const interval = setInterval(() => {
      if (news.data && news.data.length !== 0) {
        handleNext();
      }
    }, 10000);

    return () => clearInterval(interval); // Pulisci l'intervallo al dismount del componente
  }, [news.data, currentIndex]);

  const handleNext = () => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setTimeout(() => {
        setCurrentIndex((prevIndex) => {
          const nextIndex =
            news.data && news.data.length > 0
              ? (prevIndex + 1) % news.data.length
              : 0;
          setCurrentNews(news.data[nextIndex]);
          setIsTransitioning(false);
          return nextIndex;
        });
      }, 1000); // Syncs with the CSS transition time
    }
  };

  const handlePrev = () => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setTimeout(() => {
        setCurrentIndex((prevIndex) => {
          const prevIndexAdjusted =
            news.data && news.data.length > 0
              ? (prevIndex - 1 + news.data.length) % news.data.length
              : 0;
          setCurrentNews(news.data[prevIndexAdjusted]);
          setIsTransitioning(false);
          return prevIndexAdjusted;
        });
      }, 1000); // Syncs with the CSS transition time
    }
  };

  if (!news.data || news.data.length === 0 || !currentNews) {
    return (
      <div className="w-full h-10 bg-gray-600 flex items-center justify-center">
        <div className=" animate-pulse xl:w-[50%] sm:w-[80%] h-full relative flex items-center justify-between gap-4">
          <div className="text-gray-300 p-1 bg-gray-400 h-5 w-12 rounded-full"></div>
          <div className="text-gray-300 p-1 bg-gray-400 h-5 w-32 rounded-full"></div>
          <div className="text-sm h-5 w-full rounded-full bg-gray-400"></div>
          <div className="text-gray-300 p-1 bg-gray-400 h-5 w-12 rounded-full"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="relative w-full h-10 bg-gray-600 flex items-center justify-center">
      <div className="xl:w-[50%] sm:w-[80%] h-full relative flex items-center justify-between gap-4">
        <button className="text-gray-300 p-1" onClick={handlePrev}>
          &#8249;
        </button>

        <div className="flex items-center justify-between text-white text-sm h-full overflow-hidden">
          <div className="flex items-center">
            <LinkIcon
              className={`w-4 h-4 mr-2 cursor-pointer flex-shrink-0 transition-opacity duration-500 ${
                isTransitioning ? "opacity-0" : "opacity-100"
              }`}
              onClick={() => {
                if (currentNews?.url) {
                  window.open(currentNews.url, "_blank");
                }
              }}
            />
          </div>
          <div
            className={`truncate flex-grow transition-opacity duration-500 cursor-pointer ${
              isTransitioning ? "opacity-0" : "opacity-100"
            }`}
            onClick={() => {
              if (currentNews?.url) {
                window.open(currentNews.url, "_blank");
              }
            }}
          >
            {currentNews.title}
          </div>
        </div>

        <button className="text-gray-300 p-1" onClick={handleNext}>
          &#8250;
        </button>
      </div>
    </div>
  );
}
