import {
  Popover,
  PopoverBackdrop,
  PopoverButton,
  PopoverPanel,
  useClose,
} from "@headlessui/react";
import {
  ChevronDownIcon,
  ChevronRightIcon,
  LinkIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import ThemeToggle from "../appComponents/ThemeToggle";
import config from "./header_config.json";
import { useAppContext } from "./AppProvider";
import logo from "../assets/Flowberic.png";

export function findStateByPath(pathname) {
  for (const navItem of config.navigation) {
    // Direct link type
    if (navItem.type === "link" && navItem.href === pathname) {
      return navItem.state;
    }

    // Popover with sub-items
    if (navItem.type === "popover" && navItem.items) {
      const match = navItem.items.find((subItem) => subItem.href === pathname);
      if (match) {
        return match.state;
      }
    }
  }

  // Fallback state if nothing matches
  return "Home";
}

export default function Header() {
  const { state, updateContextAndRoute, isLogged, setIsLogged } =
    useAppContext();
  let close = useClose();

  const getBreadcrumb = () => {
    // 1. Find the navigation item where either the top-level item.state === state
    //    OR its sub-items contain that state
    const currentItem = config.navigation.find((item) => {
      if (item.type === "link" && item.state === state) {
        return true;
      }
      // If it’s a popover, see if any subItems match the current state
      if (
        item.type === "popover" &&
        item.items?.some((subItem) => subItem.state === state)
      ) {
        return true;
      }
      return false;
    });
    // 2. If popover, find the matching subItem
    if (currentItem?.type === "popover") {
      const activeSubItem = currentItem.items.find(
        (subItem) => subItem.state === state
      );
      return `${currentItem.label} / ${activeSubItem?.title || ""}`;
    }
    // 3. If link
    if (currentItem?.type === "link") {
      return "";
    }
    // 4. Fallback
    return "";
  };

  return (
    <div className="flex w-full justify-between items-center py-3 px-5 bg-bg-primary border-b border-bg-secondary">
      {/* Left side elements block */}
      {isLogged ? (
        <div className="flex items-center gap-4">
          {/* search */}
          <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3">
              <MagnifyingGlassIcon className="h-4 w-4 text-gray-500" />
            </div>
            <input
              type="text"
              name="search"
              placeholder="Search"
              className="h-10 pl-10 w-48 text-sm border border-transparent bg-bg-secondary 
             focus:outline-none focus:border-gray-300 focus:w-[400px] focus:bg-white
             transition-all duration-200"
            />
          </div>
          {/* Navigation */}
          <div className="flex gap-4 items-end">
            {config.navigation.map((item, index) => (
              <div
                key={index}
                className="sm:text-sm/6  text-text-primary cursor-pointer
            text-lg"
              >
                {item.type === "link" && (
                  <div
                    onClick={() => updateContextAndRoute(item.state, item.href)}
                    className={`sm:text-sm/6  ${
                      state === item.state
                        ? "text-primary"
                        : "text-text-tertiary"
                    } text-lg`}
                  >
                    {item.label}
                  </div>
                )}

                {item.type === "popover" && (
                  <Popover>
                    <PopoverButton
                      className={`flex items-center gap-1.5 sm:text-sm/6  text-text-tertiary
                   focus:outline-none data-[active]:text-text-secondary data-[hover]:text-text-secondary data-[focus]:outline-1 data-[focus]:outline-white
                   text-lg`}
                    >
                      {item.label}
                      <ChevronDownIcon className="h-4 w-4 text-text-tertiary" />
                    </PopoverButton>
                    <PopoverPanel
                      transition
                      anchor="bottom"
                      className="ml-32 divide-y divide-bg-details bg-bg-primary shadow sm:text-sm/6 transition 
                            duration-200 ease-in-out [--anchor-gap:10px] data-[closed]:-translate-y-1 data-[closed]:opacity-0
                            text-lg"
                    >
                      {({ close }) =>
                        item.items.map((subItem, subIndex) => (
                          <div key={subIndex} className="p-0">
                            <div
                              className={`block py-2 px-3 transition hover:bg-bg-secondary cursor-pointer  ${
                                state === subItem.state ? "bg-bg-details" : ""
                              }`}
                              onClick={(e) => {
                                updateContextAndRoute(
                                  subItem.state,
                                  subItem.href
                                );
                                close();
                              }}
                            >
                              <div
                                className={`flex gap-0.5 items-center ${
                                  state === subItem.state
                                    ? "text-focus-primary"
                                    : "text-text-secondary"
                                }`}
                              >
                                {subItem.title}
                                <LinkIcon className="h-4 w-4" />
                              </div>
                              <div className="text-text-tertiary sm:text-xs text-sm">
                                {subItem.description}
                              </div>
                            </div>
                          </div>
                        ))
                      }
                    </PopoverPanel>
                  </Popover>
                )}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="h-[40px]"></div>
      )}
      {/* Center elements block  */}
      <div className="absolute left-[50%] transform translate-x-[-50%]">
        <img src={logo} alt="logo" className="h-6" />
      </div>
      {/* Right side elements */}
      <div className="flex items-center gap-4">
        <ThemeToggle></ThemeToggle>
        {!isLogged ? (
          <>
            <div
              className="text-sm/6 text-text-tertiary cursor-pointer"
              onClick={() => updateContextAndRoute("Auth", "/auth/login")}
            >
              Log in
            </div>
            <div
              className="text-sm/6 text-text-button px-2 py-1 bg-button-primary cursor-pointer"
              onClick={() => updateContextAndRoute("Auth", "/auth/signup")}
            >
              Sign Up
            </div>
          </>
        ) : (
          <div>
            <div
              className="text-sm/6 text-text-tertiary cursor-pointer"
              onClick={() => {
                localStorage.removeItem("AUTH_TOKEN");
                updateContextAndRoute("Auth", "/auth/login");
                setIsLogged(false);
              }}
            >
              Log out
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
