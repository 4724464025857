import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header, { findStateByPath } from "./Header";
import client from "../client/client";

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  // Bearer token for authentication and log status
  const [token, setToken] = useState(null);
  const [isLogged, setIsLogged] = useState(false);
  // The app state, default to Authentication
  const [state, setState] = useState("Auth");
  // The news data
  const [news, setNews] = useState([]);

  // Navigation hook
  const navigate = useNavigate("/auth");
  const location = useLocation();

  // Init routine (called then at page load)
  const initRoutine = async () => {
    const storedToken = localStorage.getItem("AUTH_TOKEN");
    if (storedToken !== null && typeof storedToken !== undefined) {
      try {
        const currentUser = await client.get("/users/me");
        setToken(storedToken);
        updateContextAndRoute("Home", "/home");
        setIsLogged(true);
      } catch (error) {
        if (error.response) {
          console.log("**********************");
          setIsLogged(false);
          updateContextAndRoute("Auth", "/auth");
          setToken("");
        } else if (error.request) {
          // Request was made, but no response received
          console.error("Error request:", error.request);
        } else {
          // Something happened in setting up the request
          console.error("Error message:", error.message);
        }
      }
    } else {
      setIsLogged(false);
      updateContextAndRoute("Auth", "/auth");
      setToken("");
    }

    // const response = await client.get("/news");
    // setNews(response);
  };

  useEffect(() => {
    // Initialization routine
    initRoutine();
  }, []);

  // Startup default path and state
  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/home");
      return;
    }

    // Find the matching state from the config
    // This will update the state at any page reload based on the Router path
    const derivedState = findStateByPath(location.pathname);
    if (derivedState) {
      setState(derivedState);
    } else {
      // If no route matches, you might want to handle 404 or fallback
      setState("Home");
    }
  }, [location, navigate]);

  const updateContextAndRoute = (newState, path) => {
    if (path) {
      navigate(path);
      setState(newState);
    }
  };

  return (
    <AppContext.Provider
      value={{
        state,
        updateContextAndRoute,
        news,
        setToken,
        setIsLogged,
        isLogged,
      }}
    >
      <Header />
      <div className="sm:h-[calc(100vh-65px)] h-[calc(100vh-65px)] overflow-auto scrollable-element">
        {children}
      </div>
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
