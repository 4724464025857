import React, { useEffect, useState } from "react";
import LoginForm from "./components/LoginForm";
import SignupForm from "./components/SignupForm";
import { useAppContext } from "../AppProvider";
import { useLocation } from "react-router-dom";

export default function AuthPage() {
  const { updateContextAndRoute } = useAppContext();
  const location = useLocation();
  const [isLogin, setIsLogin] = useState(true); // State to toggle between Login and Signup forms

  // Update `isLogin` based on the current route
  useEffect(() => {
    if (location.pathname === "/auth/login") {
      setIsLogin(true);
    } else if (location.pathname === "/auth/signup") {
      setIsLogin(false);
    }
  }, [location.pathname]);

  return (
    <div className="flex flex-col w-[400px] mx-auto h-full justify-start pt-40">
      {isLogin ? (
        <>
          <LoginForm />
          <div className="flex justify-center mt-4">
            <button
              className="text-sm text-blue-500 hover:underline"
              onClick={() => {
                setIsLogin(false);
                updateContextAndRoute("Auth", "/auth/signup");
              }}
            >
              Don't have an account? Sign Up
            </button>
          </div>
        </>
      ) : (
        <>
          <SignupForm />
          <div className="flex justify-center mt-4">
            <button
              className="text-sm text-blue-500 hover:underline"
              onClick={() => {
                setIsLogin(true);
                updateContextAndRoute("Auth", "/auth/login");
              }}
            >
              Already have an account? Login
            </button>
          </div>
        </>
      )}
    </div>
  );
}
