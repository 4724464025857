import React, { useState } from "react";
import {
  Field,
  Fieldset,
  Input,
  Label,
  Legend,
  Description,
} from "@headlessui/react";
import { clsx } from "clsx";
import client from "../../../client/client";

function SignupForm() {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (event) => {
    setIsLoading(true);

    event.preventDefault(); // Prevent default form submission
    const formData = new FormData(event.target); // Collect form data
    const data = Object.fromEntries(formData.entries()); // Convert to an object

    try {
      const response = await client.post("/users", {
        email: data.username,
        password: data.password,
      });
      console.log("Sign Up successful:", response.data);
      // Perform actions after successful login (e.g., navigate to dashboard)
    } catch (error) {
      if (error.status === 400) {
        setErrorMessage("Email or username already exists");
        setIsError(true);
      }
      console.error("Sign Up failed:", error);
    }
    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit} className="min-w-[400px]">
      <Fieldset className="space-y-6 rounded-xl bg-bg-primary px-6 py-4 sm:px-10 sm:py-8 text-text-primary shadow">
        <div className="flex w-full items-center justify-between">
          <Legend className="text-base/7 font-semibold text-text-secondary">
            Sign Up
          </Legend>
          {isError ? (
            <div className="text-sm/6 text-text-error">{errorMessage}</div>
          ) : (
            <></>
          )}
        </div>
        {/* Email/Username Field */}
        <Field>
          <Label className="text-sm/6 font-medium text-text-secondary">
            Email
          </Label>
          <Input
            id="username"
            name="username"
            className={clsx(
              "mt-3 block w-full rounded-lg border-none bg-bg-secondary py-1.5 px-3 text-sm/6 text-text-primary",
              "focus:outline-none focus:ring-2 focus:ring-focus-primary"
            )}
            type="text"
            placeholder="Enter your email or username"
            required
          />
        </Field>
        {/* Password Field */}
        <Field>
          <Label className="text-sm/6 font-medium text-text-secondary">
            Password
          </Label>
          <Input
            id="password"
            name="password"
            className={clsx(
              "mt-3 block w-full rounded-lg border-none bg-bg-secondary py-1.5 px-3 text-sm/6 text-text-primary",
              "focus:outline-none focus:ring-2 focus:ring-focus-primary"
            )}
            type="password"
            placeholder="Enter your password"
            required
          />
        </Field>
        {/* Remember Me and Submit */}
        <div className="w-full flex items-center justify-end">
          <button
            type="submit"
            className={clsx(
              "w-32 inline-flex justify-center rounded-lg bg-button-primary px-4 py-2 text-sm/6 font-medium text-text-button",
              "hover:bg-button-hover focus:outline-none focus:ring-2 focus:ring-focus-primary",
              isLoading && "animate-pulse" // Add the pulsing animation if isLoading is true
            )}
            disabled={isLoading}
          >
            {isLoading ? "Logging..." : "Sign Up"}
          </button>
        </div>
      </Fieldset>
    </form>
  );
}

export default SignupForm;
