import React, { useEffect, useRef, useState } from "react";
import UplotReact from "uplot-react";
import "uplot/dist/uPlot.min.css";

const DataChart = ({ title, inputData }) => {
  const [data, setData] = useState(null);
  const [options, setOptions] = useState(null);
  const chartRef = useRef(null);
  const legendRef = useRef(null);

  useEffect(() => {
    if (!inputData || inputData.length === 0) {
      console.error("No input data provided.");
      return;
    }

    // Process data for uPlot
    const seriesData = inputData.map((dataset) => {
      const dates = dataset.data.map(
        (item) => new Date(item.time).getTime() / 1000
      ); // Convert to UNIX timestamp
      const values = dataset.data.map((item) => item._value);
      return { name: dataset.name, dates, values };
    });

    const dates = seriesData[0]?.dates || []; // Use dates from the first dataset as the x-axis
    const valuesArray = seriesData.map((dataset) => dataset.values);

    // Prepare data structure for uPlot
    const uplotData = [dates, ...valuesArray];

    // Create series configuration
    const uplotSeries = [
      {}, // The first series is for the x-axis (time)
      ...seriesData.map((dataset, index) => ({
        stroke:
          index == 0
            ? "black"
            : `hsl(${(index * 360) / seriesData.length}, 70%, 50%)`,
        points: { show: false },
        width: 2,
        fill: index == 0 ? "" : "rgba(255, 0, 0, 0.2)",

        snapGaps: true,

        // in-legend display
        label: dataset.name,
        value: (self, rawValue) => "$" + rawValue,
      })),
    ];

    // Set options for uPlot
    setOptions({
      title: `${title}`,
      width: 800,
      height: 400,
      scales: {
        x: {
          time: true,
        },
        y: {
          auto: true,
        },
      },
      series: uplotSeries,
      axes: [
        {
          grid: { show: false }, // Remove vertical grid lines
          scale: "x",
        },
        {
          grid: { show: true }, // Keep horizontal grid lines
          scale: "y",
        },
      ],
      legend: {
        show: false, // Remove the bottom legend
      },
      hooks: {
        setCursor: [
          (u) => {
            if (legendRef.current) {
              const cursorLeft = u.cursor.left;
              const cursorTop = u.cursor.top;

              if (
                cursorLeft == null ||
                cursorTop == null ||
                u.cursor.idx == null
              ) {
                // Hide the legend if cursor is outside the chart
                legendRef.current.style.display = "none";
              } else {
                // Retrieve values for the legend
                const idx = u.cursor.idx;
                const values = u.series
                  .map((s, i) => {
                    if (i === 0 || !s.show) return null; // Skip x-axis
                    const color = s._stroke || "black"; // Default to black if stroke is undefined
                    return `
                            <span>
                              <span style="display: inline-block; width: 8px; height: 8px; background-color: ${color}; border-radius: 50%; margin-right: 5px;"></span>
                              ${s.label}: ${u.data[i][idx]}
                            </span>
                          `;
                  })
                  .filter(Boolean);

                // Format the x value (date)
                const xValue = new Intl.DateTimeFormat("en-US", {
                  weekday: "short", // Include full weekday name
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                }).format(new Date(u.data[0][idx] * 1000));

                // Include the date in the legend
                legendRef.current.innerHTML = `
                                                <div style="font-size: 11px; line-height: 1.2;">
                                                  <span style="font-size: 10px; color: gray;">${xValue}</span><br>
                                                  ${values.join("<br>")}
                                                </div>
                                              `;

                const rect = u.root.getBoundingClientRect(); // Chart container bounding box
                // Adjust legend position relative to the chart container
                const legendX = cursorLeft - legendRef.current.offsetWidth + 30; // Add offset for better placement
                const legendY = cursorTop + 20; // Add offset for better placement

                legendRef.current.style.transform = `translate(${legendX}px, ${legendY}px)`;
                legendRef.current.style.display = "block"; // Make legend visible
              }
            }
          },
        ],
      },
    });

    setData(uplotData);
  }, [inputData, title]);

  return (
    <div style={{ position: "relative" }}>
      {data && options ? (
        <UplotReact options={options} data={data} target={chartRef.current} />
      ) : (
        <p>Loading chart...</p>
      )}
      <div
        ref={legendRef}
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          pointerEvents: "none",
          backgroundColor: "rgba(255, 255, 255, 1)",
          // width: "200px",
          overflow: "hidden",
          border: "1px solid #ccc",
          padding: "5px",
          fontSize: "12px",
          whiteSpace: "nowrap",
        }}
      ></div>
    </div>
  );
};

// // Navigation plugin implementation
// function uPlotNavPlugin({ className, initial }) {
//   return {
//     hooks: {
//       init: (u) => {
//         const el = document.createElement("div");
//         el.className = className;
//         u.root.appendChild(el);

//         // Implement custom navigation logic as required
//         // Use `initial` values for the initial zoom level
//       },
//     },
//   };
// }

export default DataChart;
