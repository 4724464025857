import React from "react";
// import AuthPage from "./context/auth/AuthPage";
// import ThemeToggle from "./appProvider/appComponents/ThemeToggle";
import "./assets/App.css";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import { AppProvider } from "./context/AppProvider";
import Home from "./context/home/Home";
import Dashboards from "./context/dashboards/Dashboards";
import AuthPage from "./context/auth/AuthPage";

function App() {
  const routes = [
    { path: "/auth", component: <AuthPage />, state: "Auth" },
    { path: "/auth/login", component: <AuthPage />, state: "Auth" },
    { path: "/auth/signup", component: <AuthPage />, state: "Auth" },
    { path: "/home", component: <Home />, state: "Home" },
    // { path: "/dashboards", component: <Dashboards />, state: "Dashboards" },
    // { path: "/profile", component: <ProfilePage />, state: "Profile" },
  ];

  return (
    <div className="bg-bg-primary">
      <Router>
        <AppProvider>
          <Routes>
            {routes.map(({ path, component }) => (
              <Route key={path} path={path} element={component} />
            ))}
          </Routes>
        </AppProvider>
      </Router>
    </div>
  );
}

export default App;
