import React, { useEffect, useState } from "react";
import Banner from "./components/Banner";
import DataChart from "../../appComponents/DataChart";
import client from "../../client/client";

export default function Home() {
  const [data, setData] = useState(null);

  useEffect(() => {
    const ticker = "AAPL";
    const fetchData = async () => {
      const response = await client.get(`/tickers/${ticker}`, {
        params: {
          metrics: ["close"],
        },
        paramsSerializer: (params) => {
          const query = new URLSearchParams();
          Object.keys(params).forEach((key) => {
            if (Array.isArray(params[key])) {
              params[key].forEach((val) => query.append(key, val));
            } else {
              query.append(key, params[key]);
            }
          });
          return query.toString();
        },
      });
      const jsonData = response.data;

      const response1 = await client.get(`/tickers/GOOGL`, {
        params: {
          metrics: ["close"],
        },
        paramsSerializer: (params) => {
          const query = new URLSearchParams();
          Object.keys(params).forEach((key) => {
            if (Array.isArray(params[key])) {
              params[key].forEach((val) => query.append(key, val));
            } else {
              query.append(key, params[key]);
            }
          });
          return query.toString();
        },
      });
      const jsonData1 = response1.data;
      setData([
        { name: "AAPL", data: jsonData },
        { name: "GOOGL", data: jsonData1 },
      ]);
    };
    fetchData();
  }, []);

  return (
    <div>
      <Banner />
      <div className="flex w-full justify-center mt-[300px]">
        <DataChart inputData={data} title={"title"} />
      </div>
    </div>
  );
}
