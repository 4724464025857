import axios from "axios";

// Base URL configuration
const baseURL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8000";

// Create an Axios instance
const client = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Add a request interceptor to include the Bearer token
client.interceptors.request.use(
  (config) => {
    // Get the token from localStorage or another secure place
    const token = localStorage.getItem("AUTH_TOKEN"); // Adjust as needed
    if (token) {
      // Attach the token to the Authorization header
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add an interceptor to handle responses
client.interceptors.response.use(
  (response) => {
    return response.data; // Return only the data from the response
  },
  (error) => {
    // Handle errors globally if needed
    console.error("API error:", error.response || error.message);
    return Promise.reject(error);
  }
);

export default client;
